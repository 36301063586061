import { DocumentStatus, GeneratedDocumentStatus } from '@connect-core/connect-sdk';
import omit from 'lodash/omit';
// type gaurd in case TypeScript still complains when you already checked
export function isNotNull<T>(value: T | null): value is T {
    return value !== null;
}

export function isValidText(fileName: string): boolean {
    return /^[\w\-. ]+$/.test(fileName);
}

export const removeKeysFromObject = <T extends object, K extends keyof T>(obj: T, ...keysToRemove: K[]): Omit<T, K> =>
    omit(obj, keysToRemove) as Omit<T, K>;

export const removeKeysFromArrayObjects = <T extends object, K extends keyof T>(
    array: T[],
    ...keysToRemove: K[]
): Omit<T, K>[] => array.map((obj) => removeKeysFromObject(obj, ...keysToRemove));

type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

export const removeKeysFromObjectDeep = <T>(obj: T, keysToRemove: string[]): DeepPartial<T> => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((element) => removeKeysFromObjectDeep(element, keysToRemove)) as unknown as DeepPartial<T>;
    }

    const cleanedObj = omit(obj, keysToRemove);
    const result: DeepPartial<T> = {};

    Object.keys(cleanedObj).forEach((key) => {
        const typedKey = key as keyof typeof cleanedObj;
        result[typedKey] = removeKeysFromObjectDeep(
            cleanedObj[typedKey],
            keysToRemove
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;
    });

    return result;
};

// example: const { cancel } = await waitAndExecute(1000, refetchDocuments);
export async function waitThenHandle<T>(
    delay: number,
    callback: () => T | Promise<T>
): Promise<{ cancel: () => void }> {
    let timeoutId: ReturnType<typeof setTimeout>;
    let isCancelled = false;

    const cancel = () => {
        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }
        isCancelled = true;
    };

    await new Promise((resolve) => {
        timeoutId = setTimeout(resolve, delay);
    });

    if (!isCancelled) {
        await Promise.resolve(callback());
    }

    return { cancel };
}

// keysToReplace: [{ key: 'email', replaceWith: 'contactEmail'}]
type keysToReplaceParam = { key: string; replaceWith: string }[];
export const formDataToObject = (formData: FormData, keysToReplace?: keysToReplaceParam) => {
    const data: { [key: string]: FormDataEntryValue } = {};

    for (const [key, value] of formData.entries()) {
        const keyToReplace = keysToReplace?.find((keyData) => keyData.key === key);
        data[keyToReplace ? keyToReplace.replaceWith : key] = value;
    }

    return data;
};

// ====> other
// turns logic within className into a string to make sure no 'undefined' etc. show up in the DOM
type Classes = string | undefined | false | null;
export const cx = (...classes: Classes[]) => classes.filter(Boolean).join(' ');

export const getColorForDocumentStatus = (status: DocumentStatus) => {
    switch (status) {
        case DocumentStatus.New:
            return 'blue';
        case DocumentStatus.Mapped:
            return 'green';
        case DocumentStatus.Unmapped:
            return 'orange';
        case DocumentStatus.Processed:
            return 'purple';
        case DocumentStatus.Deleted:
        default:
            return 'gray';
    }
};

export const getColorForGeneratedDocumentStatus = (status: GeneratedDocumentStatus) => {
    switch (status) {
        case GeneratedDocumentStatus.New:
        case GeneratedDocumentStatus.Reviewed:
            return 'blue';
        case GeneratedDocumentStatus.Verified:
            return 'pink';
        case GeneratedDocumentStatus.Downloaded:
            return 'orange';
        case GeneratedDocumentStatus.Sent:
            return 'green';
        default:
            return 'gray';
    }
};
